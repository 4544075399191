import { useForm } from 'react-hook-form';
import React from 'react';

import PlugAndPlayImage from 'assets/images/plugAndPlay.png';
import {
  ActionPortal,
  Checkbox,
  Col,
  DefaultActionButtons,
  Divider,
  FormGroup,
  FormLabel,
  MaskedInput,
  Paragraph,
  Row,
  Step,
  Switch,
  Text,
  TextBold,
} from 'components';

import { StepPropTypes } from '../../constants/propTypes';

export default function PlugAndPlay(props) {
  const {
    theme, onSubmit, onCancel, initialValues: { plugAndPlay, vehicleManager } = {},
  } = props;

  const {
    control, errors, formState, handleSubmit, register, watch,
  } = useForm({
    mode: 'all',
    defaultValues: plugAndPlay,
  });

  const { isValid } = formState;
  const usePlugAndPlay = watch('usePlugAndPlay');

  const submitForm = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  const hasOnlyOneLightweightVehicle = vehicleManager && vehicleManager.lightweightVehiclesCount === 1;

  return (
    <Step
      title="Plug and Play (opcional)"
      subtitle="Algum dos seus veículos precisa de Plug and Play?"
    >
      {vehicleManager && vehicleManager.lightweightVehiclesCount < 1 &&(<Paragraph
        fontSize="12px"
        lineHeight="20px"
        color={'#E65845'}
      >
        Os veículos selecionados para seu contrato não suportam essa opção.
      </Paragraph>)}
      <form onKeyPress={e => e.key === 'Enter' && submitForm(e)}>
        <div style={{ margin: '24px 0', display: 'flex' }}>
          <Switch
            inline
            innerRef={register}
            type="switch"
            id="usePlugAndPlay"
            name="usePlugAndPlay"
            label="Quero Plug and Play"
            disabled={vehicleManager && vehicleManager.lightweightVehiclesCount < 1}
          />
        </div>
        {usePlugAndPlay && (
          <>
            <FormGroup>
              {hasOnlyOneLightweightVehicle  
                ? <FormLabel for="vehicleCount">
                    O seu <TextBold display={'inline'}> único </TextBold> Veículo leve vai ter Plug and Play?
                  </FormLabel>
                : <FormLabel for="vehicleCount">
                    Quantos dos seus <TextBold display={'inline'}> {vehicleManager.lightweightVehiclesCount} </TextBold> Veículos leves vão ter Plug and Play?
                  </FormLabel>
              }
              <MaskedInput
                control={control}
                name="vehicleCount"
                defaultValue=""
                format="numeric"
                errors={errors}
                placeholder="Digite a quantidade de veículos"
                maxLength="3"
                rules={{
                  validate: {
                    maxNumber: (value) => value > 0 && value <= vehicleManager.lightweightVehiclesCount,
                  },
                }}
              />
            </FormGroup>
            <Divider margin="24px 0" />
          </>
        )}
        <Row
          style={{
            alignItems: 'center',
            border: `1px solid ${theme.colors.accent}`,
            margin: '1em 0',
            borderRadius: '0.5em',
            padding: '24px',
          }}
        >
          <Col
            md="5"
            sm="12"
          >
            <img
              style={{ margin: 'auto', maxWidth: '150px' }}
              src={PlugAndPlayImage}
              alt="Plug and play"
            />
          </Col>
          <Col
            md="7"
            sm="12"
          >
            <Text
              color={theme.colors.text.default}
              fontSize="16px"
              fontWeight="700"
              fontHeight="20px"
              whiteSpace="normal"
              margin="0 0 1em 0"
            >
              Plug and Play
            </Text>
            <Paragraph
              fontSize="14px"
              lineHeight="22px"
            >
              Com o Rastreador Plug and Play, basta encaixar o dispositivo na entrada OBD do
              veículo, que o sistema já estará funcionando, sem a necessidade de uma instalação
              com fio. O Rastreador Plug and Play só pode ser usado em veículos leves e não tem
              Bloqueio de combustível.
            </Paragraph>
          </Col>
        </Row>
        {usePlugAndPlay &&
          <Row>
            <Checkbox
              type="checkbox"
              id="opt-in"
              name="opt-in"
              label="Sim. Estou ciente."
              inline
              innerRef={register({required: true})}
              rules={{required: true}}
            />
          </Row>
        }
        <ActionPortal>
          <DefaultActionButtons
            theme={theme}
            isValid={isValid}
            backButtonStyle={{
              iconPosition: 'left',
              border: 'none',
              customBackgroundColor: 'transparent',
              customIconColor: theme.colors.text.label,
              customTextColor: theme.colors.text.label,
              title: 'Passo Anterior',
            }}
            nextButtonStyle={{
              customBackgroundColor: theme.colors.primary,
              customIconColor: theme.colors.text.white,
              customTextColor: theme.colors.text.white,
              title: 'Próximo Passo',
            }}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(onSubmit)()}
          />
        </ActionPortal>
      </form>
    </Step>
  );
}

PlugAndPlay.propTypes = StepPropTypes;
